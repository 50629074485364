import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import vibeImage from "../images/vibe.png"

const GET_SPOTS = gql`
  query getBetaSpots {
    getBetaSpots
  }
`

export default function Ksu() {
  const { loading, error, data } = useQuery(GET_SPOTS)

  return (
    <PageWrapper>
      <Wrapper>
        <BackgroundImage>
          <TitleBox>
            <Title>VIBE</Title>
            <SubTitle>party w/ your friends👀</SubTitle>
          </TitleBox>
          <ButtonBox>
            {data?.getBetaSpots ? (
              <>
                <DescText>
                  join{" "}
                  {5000 - data.getBetaSpots > 50
                    ? 5000 - data.getBetaSpots
                    : "many"}{" "}
                  others at KSU🤫
                </DescText>
                <GreyText>{data.getBetaSpots} invites left</GreyText>
              </>
            ) : (
              <>
                <DescText>join others at KSU🤫</DescText>
                <GreyText>limited invites left</GreyText>
              </>
            )}
            <a
              style={{ textDecorationLine: "none" }}
              href="https://testflight.apple.com/join/V1XuPHke"
            >
              <Button>
                <ButtonText>get VIBE</ButtonText>
              </Button>
            </a>
          </ButtonBox>
        </BackgroundImage>
      </Wrapper>
    </PageWrapper>
  )
}

// Page Wrapper
const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #121212;
`

// Content wrapper
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  aling-items: center;
  flex-direction: row;

  align-self: center;
  background-color: #121212;
  align-items: center;
  justify-content: center;
`

const BackgroundImage = styled.div`
  background-image: url(${vibeImage});
  width: 100vw;
  max-width: 600px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  display: flex;
  justify-content: center;
  aling-items: center;
  flex-direction: column;
  margin-bottom: 100px;
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.p`
  font-family: Gotham;
  font-size: 55px;
  letter-spacing: 2px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fdde68;
`
const SubTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 28px;
  width: 200px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
`

const DescText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  width: 160px;
  margin-bottom: 8px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
`

const GreyText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #8a8a8a;
  font-size: 18px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
  margin-bottom: 25px;
`

const Button = styled.div`
  width: 170px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #fdde68;
`

const ButtonText = styled.p`
  text-align: center;
  vertical-align: middle;
  margin: 0;
  margin-bottom: 1px;
  text-decoration: none;
  color: #333;

  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
  font-weight: 900;
  font-size: 20px;
  line-height: 50px;
`
